<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyImport()" autocomplete="off">
          <div class="row">
            <div class="col-md-12">
              <p class="text-muted">Antes de importar,  segue o nosso modelo de layout. <a href="https://xcode-eventfy.sfo3.digitaloceanspaces.com/cursoisaac/modelimport.xlsx" target="_blank" class="link">Baixar Modelo de Importação</a></p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="domain">Arquivo</label>
                <b-form-file
                  v-model="user.file"
                  browse-text="Procurar"
                  accept="file/*"
                  placeholder="Escolha um arquivo para importar"
                  drop-placeholder="Jogue arquivo aqui..."
                ></b-form-file>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyImport()">Importar</button>
    </template>
  </b-modal>
</template>
<script>
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions } from 'vuex'

export default {
  name: 'participant-import',
  components: {
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Importar Participantes',
      user: {},
      modal: false
    }
  },
  methods: {
    ...mapActions('participant', {
      importBase: 'importUsers'
    }),
    open () {
      this.modal = true
    },
    async applyImport () {
      let loader = this.$loading.show()
      try {
        const formData = new FormData()
        if (this.user.file) {
          formData.append(`file`, this.user.file, `listimport.xlsx`)
        }
        await this.importBase(formData).then(() => {
          let message = 'Sucesso: Lista em processamento.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
          this.$emit('usersImported')
          this.close()
        })
      } catch (err) {
        console.log(err)
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    close () {
      this.modal = false
    },
    clean () {
      this.user = {}
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.form-check-label { padding-top: 2px !important }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
